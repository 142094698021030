<template>
  <!-- 面试通知 -->
  <div class="interviewNotice">
    <van-nav-bar title="面试信息"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
      <template #right>
        <van-icon name="ellipsis"
                  size="20" />
      </template>
    </van-nav-bar>
    <div class="fllow_model">
      <div class="person_lists">
        <div class="person_grid"
             v-for="(item,index) in 6"
             :key="index">
          <div class="head_card">
            <van-badge>
              <div class="child" />
              <template #content>
                <van-icon name="minus"
                          class="badge-icon"
                          @click="cutHead" />
              </template>
              <img src="/img/interview_pic1.e86dad2c.png"
                   alt="">
            </van-badge>
          </div>
          <span>陈先生</span>
        </div>
      </div>
      <div class="check_notices">
        <van-field class="template_cell"
                   v-model="Template"
                   :label="TemplateLable"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   required
                   right-icon="arrow"
                   @click="showActions(TemplateLable)" />
        <van-field v-model="interviewer"
                   :label="interviewerLable"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   required
                   right-icon="arrow"
                   @click="showActions(interviewerLable)" />
        <van-field readonly
                   clickable
                   required
                   :value="interviewTime"
                   :label="interviewTimeLable"
                   input-align="right"
                   name="datetimePicker"
                   placeholder="请选择"
                   right-icon="arrow"
                   @click="showTime = true" />
        <van-popup v-model="showTime"
                   round
                   position="bottom">
          <van-datetime-picker v-model="currentDate"
                               type="datetime"
                               title="选择面试时间"
                               :min-date="minDate"
                               @confirm="onConfirm"
                               @cancel="showTime = false" />
        </van-popup>
        <van-field class="interviewTurn_cell"
                   v-model="interviewTurn"
                   :label="interviewTurnLable"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   required
                   right-icon="arrow"
                   @click="showActions(interviewTurnLable)" />
        <van-field v-model="interviewType"
                   :label="interviewTypeLable"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   required
                   right-icon="arrow"
                   @click="showActions(interviewTypeLable)" />
        <van-field v-model="interviewMsg"
                   :label="interviewMsgLable"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   required
                   right-icon="arrow"
                   @click="showActions(interviewMsgLable)" />
        <van-action-sheet v-model="show"
                          :actions="actions"
                          :title="actionLable"
                          cancel-text="取消"
                          :closeable="true"
                          @select="onSelect">
          <!-- <template #default>
          <div v-for="(acs,index) in actions"
               :key="index"
               @click="onSelect(acs)">
            {{acs.name}}
          </div>
        </template> -->
        </van-action-sheet>

      </div>
      <div class="description">
        将用来给应聘者发送面试通知
      </div>
      <div class="notic_div">
        <van-cell center
                  title="短信通知">
          <template #right-icon>
            <van-switch v-model="checkeNote"
                        size="20" />
          </template>
        </van-cell>
        <van-cell center
                  title="邮件通知">
          <template #right-icon>
            <van-switch v-model="checkeEmail"
                        size="20" />
          </template>
        </van-cell>
        <van-cell center
                  title="微信">
          <template #right-icon>
            <van-switch v-model="checkeWechat"
                        size="20" />
          </template>
        </van-cell>
      </div>
      <div class="description">
        选择给应聘者发送面试通知的方式
      </div>
      <!-- <div>
        <van-button type="info">发送面试通知</van-button>
      </div> -->
      <div class="send_btn"
           style="padding: 16px 60px;">
        <van-button round
                    block
                    type="info"
                    native-type="submit">发送面试通知</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      leftToPath: "",
      show: false,
      actionLable: '',
      actions: [],
      Template: '',
      TemplateLable: '模板名称',
      interviewer: '',
      interviewerLable: '面试官',
      interviewTime: '',
      interviewTimeLable: '面试时间',
      interviewTurn: '',
      interviewTurnLable: '面试轮次',
      interviewType: '',
      interviewTypeLable: '面试方式',
      interviewMsg: '',
      interviewMsgLable: '面试信息',
      showTime: false,
      minDate: new Date(),
      // maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      checkeNote: true,
      checkeEmail: true,
      checkeWechat: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.leftToPath = from.fullPath
    })
  },
  methods: {
    onClickLeft () {
      // this.$router.push("/arrangeInterview")
      this.$router.push(this.leftToPath)
    },
    onClickRight () { },
    // 清除头像
    cutHead () {
      console.log('这是谁的头啊？');
    },
    // 选择面试时间确认
    onConfirm (value) {
      let dateTime = ''
      if (value)
      {
        let y = value.getFullYear()
        let m = value.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        let d = value.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = value.getHours()
        h = h < 10 ? ('0' + h) : h
        let M = value.getMinutes()
        M = M < 10 ? ('0' + M) : M
        //  let s =date.getSeconds()
        //  s = s < 10 ? ('0' + s) : s
        dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M;
      }
      this.interviewTime = dateTime
      this.showTime = false
    },
    showActions (item) {
      // 根据不同类型去查actions
      switch (item)
      {
        case '模板名称':
          this.actions = [{ name: '文员面试模板', className: 'template' }, { name: '技术面试模板', className: 'template' }, { name: '通用面试模板', className: 'template' }]
          break;
        case '面试官':
          this.actions = [{ name: '面试官一', className: 'interviewer' }, { name: '面试官二', className: 'interviewer' }, { name: '面试官三', className: 'interviewer' }]
          break;
        case '面试轮次':
          this.actions = [{ name: '初试', className: 'interviewTurn' }, { name: '复试', className: 'interviewTurn' }, { name: '轮次三', className: 'interviewTurn' }]
          break;
        case '面试方式':
          this.actions = [{ name: '个人面试', className: 'interviewType' }, { name: '电话面试', className: 'interviewType' }, { name: '视频面试', className: 'interviewType' }]
          break;
        case '面试信息':
          this.actions = [{ name: '信息一', className: 'interviewMsg' }, { name: '信息二', className: 'interviewMsg' }, { name: '信息三', className: 'interviewMsg' }]
          break;

        default:
          break;
      }
      this.show = true
      this.actionLable = item
    },
    // 下拉选择确认项
    onSelect (action) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      // item.value = action.name
      switch (action.className)
      {
        case 'template':
          this.Template = action.name
          break;
        case 'interviewer':
          this.interviewer = action.name
          break;
        case 'interviewTurn':
          this.interviewTurn = action.name
          break;
        case 'interviewType':
          this.interviewType = action.name
          break;
        case 'interviewMsg':
          this.interviewMsg = action.name
          break;

        default:
          break;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.interviewNotice {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    .van-icon-ellipsis {
      color: #fff;
    }
  }
  .fllow_model {
    overflow: auto;
    height: calc(100% - 1.22667rem);
  }
  .person_lists {
    padding: 40px 0;
    display: flex;
    overflow-x: auto;
    align-items: center;
    background-color: #fff;
    .person_grid {
      margin: 15px;
      flex: 0 0 160px;
      text-align: center;
      .head_card {
        align-items: center;
        display: flex;
        /* text-align: center; */
        justify-content: center;
        width: 100%;
        // height: 106px;
        .badge-icon {
          display: block;
          font-size: 10px;
          line-height: 34px;
        }
        img {
          width: 106px;
          height: 106px;
        }
      }
      span {
        margin: 10px 0;
        display: block;
        font-size: 0.53333rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
  .check_notices {
    ::v-deep .van-field__label {
      color: #000000;
    }
    .template_cell {
      margin: 20px 0;
    }
    .template_cell::after {
      border-bottom: unset;
    }
    .interviewTurn_cell {
      margin-bottom: 20px;
    }
    .interviewTurn_cell::after {
      border-bottom: unset;
    }
  }
  .notic_div {
    .van-cell__title {
      color: #000000;
    }
  }
  .description {
    color: #666666;
    font-size: 28px;
    text-align: left;
    margin: 32px;
  }
  .send_btn {
    // padding: 16px 60px;
    background: #fff;
    .van-button {
      border-radius: 0.24rem;
    }
  }
}
</style>